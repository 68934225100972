import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TokenData, User } from '../models/user';
import { environment } from 'src/environments/environment';
import { KeycloakService } from './keycloak.service';
import jwt_decode from 'jwt-decode';
import { BaseDataService } from './base-data.service';
import { CompanyLookupModel } from 'src/app/shared/models/common-data.model';
import { CRM_ADMIN_ROLE } from 'src/app/shared/data/constants';
import { LookupsService } from 'src/app/shared/services/lookups.service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  private loggedInSubject = new BehaviorSubject<boolean>(false);
  public loggedIn = this.loggedInSubject.asObservable();
  private refreshToken: string;
  private authUrl = `${environment.authUrl}/realms/${environment.realm}/protocol/openid-connect/token`;
  public currentCompany: CompanyLookupModel;

  constructor(
    private http: HttpClient,
    private keyCloackService: KeycloakService,
    private baseDataService: BaseDataService,
    private lookupsService: LookupsService
  ) {
    this.currentUserSubject = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  public setLoggedIn(value: boolean): void {
    this.loggedInSubject.next(value);
  }

  login(username: string, password: string) {
    const myheader = new HttpHeaders().set(
      'Content-Type',
      'application/x-www-form-urlencoded'
    );
    let body = new URLSearchParams();

    body.set('username', username);
    body.set('password', password);
    body.set('grant_type', 'password');
    body.set('client_id', environment.clientId);
    body.set('client_secret', environment.clientSecret);

    return this.http
      .post<any>(this.authUrl, body.toString(), { headers: myheader })
      .pipe(
        map((res) => {
          var token_data: TokenData = jwt_decode(res.access_token);

          var roles = token_data?.realm_access?.roles ?? [];

          var user: User = {
            firstName: token_data.given_name,
            lastName: token_data.family_name,
            img: '',
            id: token_data.sub,
            password: '',
            role: roles,
            username: token_data.preferred_username,
            name: token_data.name,
            token: res.access_token,
            email: token_data.email,
            refresh_token: res?.refresh_token,
            email_verified: token_data.email_verified,
          };

          this.setLoggedIn(true);

          localStorage.setItem('currentUser', JSON.stringify(user));
          this.currentUserSubject.next(user);

          return user;
        })
      );
  }

  setRefreshToken(refreshToken: string) {
    this.refreshToken = refreshToken;
  }

  getRefreshToken(): Observable<any> {
    const body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', environment.clientId);
    body.set('client_secret', environment.clientSecret);
    body.set('refresh_token', this.refreshToken);

    return this.http.post(this.authUrl, body.toString(), {
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    });
  }

  getPolicyRoles(userId: string, companyId: string = null) {
    var para = '';

    if (companyId) {
      para = '?companyId=' + companyId;
    }
    return this.baseDataService.makeGetCall(
      'api/UserInformation/GetPolicyRolesByUserId/' + userId + para
    );
  }

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
    localStorage.setItem('lang', 'en');
    localStorage.setItem('isRtl', 'false');

    return of({ success: false });
  }

  public setCurrentCompany(currentCompany: CompanyLookupModel) {
    this.currentCompany = currentCompany;
    localStorage.setItem('currentCompany', JSON.stringify(currentCompany));
  }

  public get getCurrentCompanyValue(): CompanyLookupModel {
    var company = localStorage.getItem('currentCompany') ?? null;
    return company ? JSON.parse(company) : null;
  }

  getUserCompanies() {
    return JSON.parse(localStorage.getItem('userCompanies')) ?? [];
  }

  getCurrentCompany() {
    return JSON.parse(localStorage.getItem('currentCompany')) ?? null;
  }

  fetchUserCompanies(): Observable<any> {
    var isCRMAdmin = this.currentUserValue.role;
    return this.lookupsService.getUserCompanylookUp(
      isCRMAdmin.includes(CRM_ADMIN_ROLE)
    );
  }
}
