import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataTypes } from '../../../../shared/data/constants';
import { HelpersService } from '../../../../shared/services/helpers.service';
import { LookupsService } from '../../../services/lookups.service';
import { ReplaySubject, takeUntil } from 'rxjs';
import { LookUpElement } from '../../../models/common-data.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-custom-field-form',
  templateUrl: './custom-field-form.component.html',
  styleUrls: ['./custom-field-form.component.scss'],
})
export class CustomFieldFormComponent {
  @Input() formArray!: FormArray;
  @Input() parentForm!: FormGroup;
  @Input() formArrayName!: string;

  dataType = DataTypes;
  dynamicFieldConfigurationStatus: boolean;
  destroy: ReplaySubject<any> = new ReplaySubject<any>(1);

  constructor(
    public helpersService: HelpersService,
    private lookupsService: LookupsService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {
    this.dynamicFieldConfigurationStatus =
      this.helpersService.getDynamicFieldConfigurationStatus();
  }

  onClickDataSubCategory(index: number) {
    const customField = this.formArray.value[index];
    let message: string | null = null;

    const hasSubCategories =
      customField.CustomDataSubCategoriesIds &&
      customField.CustomDataSubCategoriesIds.length > 0;
    const hasCategories =
      customField.CustomDataCategoriesIds &&
      customField.CustomDataCategoriesIds.length > 0;

    if (!hasSubCategories) {
      if (!hasCategories) {
        message =
          this.translateService.instant('MASTER_MENU.PLEASE_SELECT_THE') +
          ' ' +
          customField.Label;
      } else if (customField?.CustomDataSubCategories?.length === 0) {
        message =
          this.translateService.instant(
            'MASTER_MENU.NO_DATA_AVAILABLE_FOR_SELECTED'
          ) +
          ' ' +
          customField.Label;
      }
    }

    if (message) {
      this.helpersService.showErrorToast(message);
    }
  }
}
