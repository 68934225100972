import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DataTypes } from '../../../../shared/data/constants';
import { HelpersService } from '../../../../shared/services/helpers.service';

@Component({
  selector: 'app-custom-field-view',
  templateUrl: './custom-field-view.component.html',
  styleUrls: ['./custom-field-view.component.scss'],
})
export class CustomFieldViewComponent {
  @Input() formArray!: FormArray;
  @Input() parentForm!: FormGroup;
  @Input() formArrayName!: string;

  dataType = DataTypes;
  dynamicFieldConfigurationStatus: boolean;

  constructor(public helpersService: HelpersService) {}

  ngOnInit(): void {
    this.dynamicFieldConfigurationStatus =
      this.helpersService.getDynamicFieldConfigurationStatus();
  }
}
