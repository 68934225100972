<div [formGroup]="parentForm" *ngIf="dynamicFieldConfigurationStatus">
  <div class="row mt-4" *ngIf="formArray?.controls?.length != 0">
    <div class="col-md-12">
      <p class="formSubTitle">
        {{ 'COMMON.ADDITIONAL_INFORMATION' | translate }}
      </p>
    </div>
  </div>

  <div [formArrayName]="formArrayName" class="row">
    <div
      *ngFor="let field of formArray?.controls; let i = index"
      [formGroupName]="i"
      class="col-md-6"
    >
      <ng-container [ngSwitch]="field?.get('DataType')?.value">
        <div *ngSwitchCase="dataType.TEXT">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="Value"
              pattern="\S(.*\S)?"
              [required]="field?.get('IsRequired')?.value ?? false"
            />
            <mat-error *ngIf="field?.get('Value').hasError('pattern')">
              {{
                'COMMON.WHITESPACE_CANT_CONTAIN_START_AND_END' | translate
              }}</mat-error
            >
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.URL">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              type="text"
              matInput
              formControlName="Value"
              pattern="\S(.*\S)?"
              [required]="field?.get('IsRequired')?.value ?? false"
            />
            <mat-error *ngIf="field?.get('Value').hasError('pattern')">
              {{
                'COMMON.WHITESPACE_CANT_CONTAIN_START_AND_END' | translate
              }}</mat-error
            >
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.NUMBER">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              type="number"
              matInput
              formControlName="Value"
              [required]="field?.get('IsRequired')?.value ?? false"
            />
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.DATE">
          <mat-form-field class="example-full-width form-input-field">
            <mat-label class="text-capitalize">{{
              field?.get('Label')?.value
            }}</mat-label>
            <input
              matInput
              [matDatepicker]="picker1"
              formControlName="Value"
              readonly
              [required]="field?.get('IsRequired')?.value ?? false"
            />
            <mat-datepicker-toggle
              matIconSuffix
              [for]="picker1"
            ></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
        </div>

        <div *ngSwitchCase="dataType.BOOLEAN">
          <mat-label class="text-capitalize">{{
            field?.get('Label')?.value
          }}</mat-label>
          &nbsp;&nbsp;
          <mat-slide-toggle formControlName="Value"></mat-slide-toggle>
        </div>

        <div *ngSwitchCase="dataType.CUSTOM">
          <div class="row" *ngIf="!field?.get('IsMultipleExists')?.value">
            <div
              [ngClass]="
                field?.get('SubConfiguration')?.value != null
                  ? 'col-md-6'
                  : 'col-md-12'
              "
            >
              <mat-form-field class="example-full-width form-input-field">
                <mat-label class="text-capitalize">{{
                  field?.get('Label')?.value
                }}</mat-label>
                <mat-select
                  formControlName="CustomDataCategoriesIds"
                  [required]="field?.get('IsRequired')?.value ?? false"
                >
                  <mat-option
                    class="form-select-option"
                    *ngFor="
                      let customCategory of field?.get('CustomDataCategories')
                        ?.value
                    "
                    [value]="customCategory.id"
                    >{{ customCategory.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div
              class="col-md-6"
              *ngIf="field?.get('SubConfiguration')?.value != null"
            >
              <mat-form-field class="example-full-width form-input-field">
                <mat-label class="text-capitalize">{{
                  field?.get('SubConfiguration')?.value.name
                }}</mat-label>
                <mat-select
                  (click)="onClickDataSubCategory(i)"
                  formControlName="CustomDataSubCategoriesIds"
                  [required]="field?.get('IsRequired')?.value ?? false"
                  [multiple]="true"
                >
                  <mat-option
                    class="form-select-option"
                    *ngFor="
                      let customCategory of field?.get(
                        'CustomDataSubCategories'
                      )?.value
                    "
                    [value]="customCategory.id"
                    >{{ customCategory.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="row" *ngIf="field?.get('IsMultipleExists')?.value">
            <div
              [ngClass]="
                field?.get('SubConfiguration')?.value != null
                  ? 'col-md-6'
                  : 'col-md-12'
              "
            >
              <mat-form-field class="example-full-width form-input-field">
                <mat-label class="text-capitalize">{{
                  field?.get('Label')?.value
                }}</mat-label>
                <mat-select
                  formControlName="CustomDataCategoriesIds"
                  [required]="field?.get('IsRequired')?.value ?? false"
                  [multiple]="true"
                >
                  <mat-option
                    class="form-select-option"
                    *ngFor="
                      let customCategory of field?.get('CustomDataCategories')
                        ?.value
                    "
                    [value]="customCategory?.id"
                    >{{ customCategory?.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>

            <div
              class="col-md-6"
              *ngIf="field?.get('SubConfiguration')?.value != null"
            >
              <mat-form-field class="example-full-width form-input-field">
                <mat-label class="text-capitalize">{{
                  field?.get('SubConfiguration')?.value.name
                }}</mat-label>
                <mat-select
                  formControlName="CustomDataSubCategoriesIds"
                  (click)="onClickDataSubCategory(i)"
                  [required]="field?.get('IsRequired')?.value ?? false"
                  [multiple]="true"
                >
                  <mat-option
                    class="form-select-option"
                    *ngFor="
                      let customCategory of field?.get(
                        'CustomDataSubCategories'
                      )?.value
                    "
                    [value]="customCategory?.id"
                    >{{ customCategory?.name }}</mat-option
                  >
                </mat-select>
              </mat-form-field>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</div>
